import React, { createContext, useState } from "react";
import { useAppDispatch } from "../../../../actions";
import { ClimatPlanWidgetModel, ClimatPlanUser, ClimatPlanUnit, ClimatPlanFamily } from "../ClimatePlanPage";
import { CO2EmissionSurvey, CO2PlanPerson, CustomQuestions } from "../CO2Plan";
import {
  addValueSource,
  addOptionSource,
  selectHighLevelSource,
  selectSponsorSolution,
  setDueYear,
  setActiveGroupIndex,
  markTimelineActionAsFulfilled,
  setEconomyCost,
  registerCO2EconomyItemLinkClick,
  saveEmissionSurvey,
} from "../co2plan.actions";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../reducers/store";

type CO2ContextProps = {
  widgets: ClimatPlanWidgetModel[] | null;
  user: ClimatPlanUser | null;
  unit: ClimatPlanUnit | null;
  family?: ClimatPlanFamily | null;
  renderWidget?: (model: ClimatPlanWidgetModel, isBorder?: boolean) => JSX.Element;
  renderTopBar?: () => JSX.Element;
  renderUserNotificationAlert?: () => JSX.Element;
  renderMoveinAutocomplete?: () => JSX.Element;
  sendShortcutHandler?: () => Promise<void>;
  historyNavigate: (route: string) => void;
  onExitActions: () => void;
  WidgetPage: number;
  showEconomyItems: boolean;
  forceUserToMember?: boolean;
  isMultiSelect?: boolean;
  addValueSource: (
    emissionSourceId: string,
    personId: string | null,
    value: number | null,
    combinedValue: number | null,
    isUpdateSelected: boolean
  ) => void;
  addOptionSource: (emissionSourceId: string, personId: string | null, optionId: string | null) => void;
  selectHighLevelSource: (
    emissionSourceId: string,
    personId: string | null,
    deselectId: string | null,
    order?: number
  ) => void;
  selectSponsorSolution: (emissionSourceGroupId: string, personId: string | null, sponsorSolutionId: string) => void;
  setDueYear: (groupId: string, personId: string | null, dueYear: number) => void;
  setActiveGroupIndex: (newIndex: number) => void;
  markTimelineActionAsFulfilled: (
    emissionSourceGroupId: string,
    emissionSourceId: string,
    personId: string | null,
    IsFulfilled: boolean,
    unitId?: string,
    tempCO2PlanId?: string | null
  ) => Promise<void>;
  setEconomyCost: (
    emissionSourceId: string,
    personId: string | null,
    userEconomyCost: number | null,
    isCustom: boolean,
    isTargetSurvey: boolean
  ) => void;
  registerCO2EconomyItemLinkClick: (
    emissionSourceGroupId: string,
    economyItemId: string,
    co2PlanId: string
  ) => Promise<void>;
  saveEmissionSurvey: (
    initialSurvey: CO2EmissionSurvey | null,
    targetSurvey: CO2EmissionSurvey | null,
    customQuestions: CustomQuestions[] | null,
    planId?: string,
    unitId?: string | undefined,
    isMultiSelect?: boolean | undefined,
    planPersons?: CO2PlanPerson[]
  ) => Promise<void>;
  setTargetValue: (value: number) => void;
  missingSourceGroups: Array<[string, string | null]>;
  isTargetSurvey: boolean;
  IsSinglePersonPlan: boolean;
  planId: string;
  targetValue: number | null;
  defaultCO2Emission: number;
  governmentGoalCO2Emission: number;
  unchangeableBasicEmission: number;
};

const defaultContextProps: CO2ContextProps = {
  widgets: null,
  user: null,
  unit: null,
  family: null,
  WidgetPage: 5,
  showEconomyItems: true,
  historyNavigate: () => {},
  onExitActions: () => {},
  addValueSource: () => {},
  addOptionSource: () => {},
  selectHighLevelSource: () => {},
  selectSponsorSolution: () => {},
  setDueYear: () => {},
  setActiveGroupIndex: () => {},
  markTimelineActionAsFulfilled: async () => {},
  setEconomyCost: () => {},
  registerCO2EconomyItemLinkClick: async () => {},
  saveEmissionSurvey: async () => {},
  setTargetValue: () => {},
  missingSourceGroups: [],
  isTargetSurvey: false,
  IsSinglePersonPlan: false,
  planId: "",
  targetValue: 0,
  defaultCO2Emission: 0,
  governmentGoalCO2Emission: 0,
  unchangeableBasicEmission: 0,
};

type CO2ContextWrapperProps = {
  children: React.ReactNode;
  widgets: ClimatPlanWidgetModel[] | null;
  user: ClimatPlanUser | null;
  unit: ClimatPlanUnit | null;
  family?: ClimatPlanFamily | null;
  forceUserToMember?: boolean;
  isMultiSelect?: boolean;
  renderWidget?: (model: ClimatPlanWidgetModel, isBorder?: boolean) => JSX.Element;
  renderUserNotificationAlert?: () => JSX.Element;
  sendShortcutHandler?: () => Promise<void>;
  historyNavigate: (route: string) => void;
  onExitActions: () => void;
  WidgetPage: number;
  showEconomyItems: boolean;
  initialsurvey: CO2EmissionSurvey;
  targetSurvey: CO2EmissionSurvey;
  isTargetSurvey: boolean;
  IsSinglePersonPlan: boolean;
  planId: string;
  defaultCO2Emission: number;
  governmentGoalCO2Emission: number;
  unchangeableBasicEmission: number;
};

export const CO2Context = createContext<CO2ContextProps>(defaultContextProps);

const CO2ContextWrapper = (props: CO2ContextWrapperProps) => {
  const [targetValue, setTargetValueToState] = useState<null | number>(null);
  const dispatch = useAppDispatch();
  const state = useSelector((state: ApplicationState) => state.co2);

  return (
    <CO2Context.Provider
      value={{
        showEconomyItems: props.showEconomyItems,
        widgets: props.widgets,
        unit: props.unit,
        user: props.user,
        family: props.family,
        forceUserToMember: props.forceUserToMember,
        isMultiSelect: props.isMultiSelect,
        renderWidget: props.renderWidget,
        historyNavigate: props.historyNavigate,
        onExitActions: props.onExitActions,
        renderUserNotificationAlert: props.renderUserNotificationAlert,
        sendShortcutHandler: props.sendShortcutHandler,
        WidgetPage: props.WidgetPage,
        addValueSource: (
          emissionSourceId: string,
          personId: string | null,
          value: number | null,
          combinedValue: number | null,
          isUpdateSelected: boolean
        ) =>
          dispatch(
            addValueSource(
              emissionSourceId,
              personId,
              value,
              combinedValue,
              props.isTargetSurvey ? props.targetSurvey : props.initialsurvey,
              props.isTargetSurvey,
              isUpdateSelected,
              props.isMultiSelect
            )
          ),
        saveEmissionSurvey: (
          initialSurvey: CO2EmissionSurvey | null,
          targetSurvey: CO2EmissionSurvey | null,
          customQuestions: CustomQuestions[] | null,
          planId?: string,
          unitId?: string | undefined,
          isMultiSelect?: boolean | undefined,
          planPersons?: CO2PlanPerson[]
        ) => {
          return dispatch(
            saveEmissionSurvey(
              initialSurvey,
              targetSurvey,
              customQuestions,
              state.tempCO2PlanId,
              planId,
              unitId,
              isMultiSelect,
              planPersons
            )
          );
        },
        addOptionSource: (emissionSourceId: string, personId: string | null, optionId: string | null) =>
          dispatch(
            addOptionSource(
              emissionSourceId,
              personId,
              optionId,
              props.isTargetSurvey ? props.targetSurvey : props.initialsurvey,
              props.isTargetSurvey
            )
          ),
        selectHighLevelSource: (
          emissionSourceId: string,
          personId: string | null,
          deselectId: string | null,
          order?: number
        ) =>
          dispatch(
            selectHighLevelSource(
              emissionSourceId,
              personId,
              deselectId,
              props.isTargetSurvey ? props.targetSurvey : props.initialsurvey,
              props.isTargetSurvey,
              order,
              props.isMultiSelect
            )
          ),
        selectSponsorSolution: (emissionSourceGroupId: string, personId: string | null, sponsorSolutionId: string) =>
          dispatch(
            selectSponsorSolution(
              emissionSourceGroupId,
              personId,
              sponsorSolutionId,
              props.isTargetSurvey ? props.targetSurvey : props.initialsurvey,
              props.isTargetSurvey
            )
          ),
        setDueYear: (groupId: string, personId: string | null, dueYear: number) =>
          dispatch(
            setDueYear(groupId, personId, dueYear, props.isTargetSurvey ? props.targetSurvey : props.initialsurvey)
          ),

        setActiveGroupIndex: (newIndex: number) =>
          dispatch(
            setActiveGroupIndex(
              newIndex,
              props.isTargetSurvey ? props.targetSurvey : props.initialsurvey,
              props.isTargetSurvey
            )
          ),
        markTimelineActionAsFulfilled: (
          emissionSourceGroupId: string,
          emissionSourceId: string,
          personId: string | null,
          IsFulfilled: boolean
        ) =>
          dispatch(
            markTimelineActionAsFulfilled(
              emissionSourceGroupId,
              emissionSourceId,
              personId,
              IsFulfilled,
              props.unit?.Id
            )
          ),
        setEconomyCost: (
          emissionSourceId: string,
          personId: string | null,
          userEconomyCost: number | null,
          isCustom: boolean,
          isTargetSurvey: boolean
        ) =>
          dispatch(
            setEconomyCost(
              emissionSourceId,
              personId,
              userEconomyCost,
              isCustom,
              isTargetSurvey ? props.targetSurvey : props.initialsurvey,
              isTargetSurvey ?? props.isTargetSurvey
            )
          ),
        registerCO2EconomyItemLinkClick: (emissionSourceGroupId: string, economyItemId: string, co2PlanId: string) =>
          dispatch(registerCO2EconomyItemLinkClick(emissionSourceGroupId, economyItemId, co2PlanId)),
        setTargetValue: (value: number) => {
          setTargetValueToState(value);
        },
        missingSourceGroups:
          (props.isTargetSurvey ? props.targetSurvey : props.initialsurvey).MissingSourceGroups ?? [],
        isTargetSurvey: props.isTargetSurvey,
        IsSinglePersonPlan: props.IsSinglePersonPlan,
        planId: props.planId,
        targetValue: targetValue,
        defaultCO2Emission: props.defaultCO2Emission,
        governmentGoalCO2Emission: props.governmentGoalCO2Emission,
        unchangeableBasicEmission: props.unchangeableBasicEmission,
      }}
    >
      {props.children}
    </CO2Context.Provider>
  );
};

export default CO2ContextWrapper;
